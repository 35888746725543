.modal-meta-kelola__empty {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
}

.modal-meta-kelola__empty__icon {
  font-size: 5rem;
  margin-bottom: 1rem;
}

.modal-meta-kelola__submapel {
  padding: 1rem;
  border-radius: .25rem;
  border: 1px solid lightgray;
  cursor: pointer;
  transition: all .1s ease-in-out;
}

.modal-meta-kelola__submapel:hover {
  border: 1px solid gray;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  transform: scale(1.03);
  background: whitesmoke;
}

.modal-meta-kelola__submapel ol {
  padding-left: 0.8rem;
}

.modal-meta-kelola__submapel + .modal-meta-kelola__submapel {
  margin: 1rem 0;
}

.modal-meta-kelola__submapel__title {
  font-size: 1.5rem;
}

.modal-meta-kelola__content__title:hover,
.modal-meta-kelola__submapel__title:hover {
  text-decoration: underline;
}

.modal-meta-kelola__submapel__info {
  
}

.modal-meta-kelola__submapel__info .badge{
  margin: .12rem;
}

.modal-meta-kelola__section {
  
}

.modal-meta-kelola__section__title {
  font-size: 1.2rem;
  font-weight: 500;
}

.modal-meta-kelola__content {
  
}
