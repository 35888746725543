#dashboard-siswa .card {
  height: 100%;
}

#dashboard-siswa [class^='col'] {
  margin-bottom: 0.3rem;
  padding: 0.3rem;
}

#dashboard-siswa .card-body,
#dashboard-siswa .card-body img {
  margin: 0px !important;
}

#dashboard-siswa__last-activity * {
  transition: all 0.25s ease-in-out;
}

#dashboard-siswa__last-activity .card-body {
  padding: 1.25rem;
}

#dashboard-siswa__last-activity:hover {
  box-shadow: 0 0 3px 1px #ff5252;
  transform: scaleX(1.03);
  cursor: pointer;
}

#dashboard-siswa__last-activity .notif-go {
  display: none;
}

#dashboard-siswa__last-activity:hover .notif-go {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

#dashboard-siswa__last-activity:hover .notif-default {
  display: none;
}

#dashboard-siswa__last-activity:hover .card-header {
  box-shadow: inset -800px 0 0 #ff5252;
  color: whitesmoke !important;
}
#dashboard-siswa__last-activity:hover .card-body {
  box-shadow: inset 800px 0 0 #ff5252;
  color: whitesmoke !important;
}

#dashboard-siswa__last-activity img {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

#dashboard-siswa__last-activity:hover img {
  transform: scale(0.8);
  box-shadow: 0 0 10px 0 black;
}
