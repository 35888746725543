.gamifikasi-header {
  background: #f2f2f2;
  padding: 5px;
  margin-bottom: 18px;
  /* font-size: 1.4em; */
  width: 100%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.costumize-header {
  background: #345dab;
  /* background:#ff5252; */
  padding: 5px;
  margin-bottom: 18px;
  /* font-size: 1.4em; */
  width: 100%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 3px;
}

.costumize-header h5 {
  color: #fff;
}

.badges {
  margin-top: 15px;
  margin-bottom: 15px;
}

.badges h6 {
  margin-bottom: 0;
  margin-top: 2px;
}

.pull-left {
  float: left;
}
