:root {
  --gradient-button-rainbow-v2-pos-x: 50%;
  --gradient-button-rainbow-v2-pos-y: 50%;
  --border-button-rainbow-v2-radius: 0.25rem;
  --border-button-rainbow-v2-gap: 2px;
}

.button-rainbow-v2 {
  position: relative;
  display: inline-block;
  border: none;
  border-radius: var(--border-button-rainbow-v2-radius);
  background: gray;
  position: relative;
  padding: var(--border-button-rainbow-v2-gap);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
}

.button-rainbow-v2>.content {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 1rem;
  white-space: nowrap;
  color: #ffffff;
  background: rgb(70, 128, 255);
  background: radial-gradient(circle at bottom, rgba(70, 128, 255, 1) 0%, rgba(0, 20, 65, 1) 72%);
  border-radius: var(--border-button-rainbow-v2-radius);
  width: calc(100% - var(--border-button-rainbow-v2-gap));
  height: calc(100% - var(--border-button-rainbow-v2-gap));
}

.button-rainbow-v2>.border,
.button-rainbow-v2>.neon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: var(--border-button-rainbow-v2-radius);
}

.button-rainbow-v2>.neon {
  filter: blur(10px);
  opacity: 0.5;
}

.gradient {
  position: absolute;
  inset: -200px;
  background: conic-gradient(from 0deg at var(--gradient-button-rainbow-v2-pos-x) var(--gradient-button-rainbow-v2-pos-y),
      #1e87ff,
      #5c13c4,
      #ff0033,
      #ffda00,
      #64bc26,
      #1e87ff);

  border-radius: var(--border-button-rainbow-v2-radius);
  animation: rotate 4s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}