/* .discuss-v2 svg {
  width: 400px;
} */

.highcharts-container {
  padding: 0;
  margin: 0;
}

@media print {
  svg {
    width: 475px;
  }

  .discuss-v2 svg {
    width: 320px;
  }

  .noprint {
    display: none;
    height: 0;
    width: 0;
  }
}

.toggle-ellipsis {
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: 1000;
}

.table-note td:nth-child(1) {
  text-align: center;
  width: 50px;
  font-weight: 700;
}
