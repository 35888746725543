:root {
  --link-color-linkify: rgb(0, 16, 193);
  --link-color-linkify-active: rgb(0, 12, 149);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}

.notif-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(0,0,0,0.5);
}

.btn-forbidden:hover {
  cursor: not-allowed;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  height: auto;
}

.html-content {
  width: 100%;
}

.html-content figure {
  display: block;
  margin: 12px auto;
}

.html-content img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  align-self: center;
}

.ck-readonly .ck-content {
  border: none !important;
  background-color: transparent !important;
  /* position: relative !important;
  z-index: 100 !important; */
}

.ck-readonly .ck-editor__top {
  display: none;
}

.ck-readonly .ck-media__wrapper div {
  position: static !important;
}

.html-content a, .html-content a span {
  color: #4680ff !important;
}

.quiz-question-preview {
  max-width: 200px;
  overflow: hidden;
} 

.quiz-question-preview img {
  display: none;
}

.breadcrumb-item p {
  margin: 0;
}

/* .table-rekap-nilai .buttons-pdf, */
/* .table-rekap-nilai .buttons-copy, */
/* .table-rekap-nilai .buttons-csv, */
/* .table-rekap-nilai .buttons-print, */
.table-rekap-nilai .dataTables_filter, 
.table-rekap-nilai .dataTables_info,
.table-rekap-nilai .dataTables_paginate
{ display: none; }

/* .table-rekap-nilai .buttons-copy, */
/* .table-rekap-nilai .buttons-csv, */
/* .table-rekap-nilai .buttons-print,  */
.table-rekap-nilai .buttons-excel
{
  background-color: #345DAB !important;
  border-color: #345DAB !important;
  border-radius: 2px !important;
  margin-right: 10px;
  margin-bottom: 7px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #345DAB !important;
}

.send-chat .msg {
  background-color: #345DAB !important;
}

.send-chat .msg:after {
  border-right-color: #345DAB !important;
  right: -5px !important;
}

/* Wiris Warning CSS */
.wrs_editor .wrs_tickContainer {
  display: none !important;
}

.shake-animation {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.table-fixed td:nth-child(1){
  position:sticky;
  left:0px;
  background-color:white;
}

.table-fixed td:nth-child(2){
  position:sticky;
  left:40px;
  background-color:white;
}

.table-fixed td:nth-child(3){
  position:sticky;
  left:85px;
  min-width: 95px;
  max-width: 95px;
  background-color:white;
}

.table-fixed td:nth-child(4){
  position:sticky;
  left:175px;
  background-color:white;
}

.table-fixed th:nth-child(1){
  position:sticky;
  left:0px;
}

.table-fixed th:nth-child(2){
  position:sticky;
  left:40px;
}

.table-fixed th:nth-child(3){
  position:sticky;
  left:85px;
  min-width: 95px;
  max-width: 95px;
}

.table-fixed th:nth-child(4){
  position:sticky;
  left:175px;
}

.table-fixed{
  text-align: center;
}

.flip-clock .flip-clock-group:nth-child(1) {
  display: none;
}

.flip-clock .flip-clock-divider:nth-child(2) {
  display: none;
}

.rotate-0{
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.rotate-90{
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-180{
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-270{
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.bg-grey{
  background: #ecf0f5 !important;
}
.word-wrap{
  word-break: break-all !important;
  white-space: unset !important;
}
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  @page { size: A4; }
  html, body {
    height: initial !important;
    overflow: initial !important;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important; 
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  /* margin: 15mm; */
}

.bg-header-kop{
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 350px;
}
.cube{
      background-color: #3B7CBF;     
      width: 20px;
      height: 20px;
}
.cube-green{
  background-color: #94CA53;     
  width: 20px;
  height: 20px;
}
.cube-red{
  background-color: #EC242B;     
  width: 20px;
  height: 20px;
}
.cube-orange{
  background-color: #E18B26;     
  width: 20px;
  height: 20px;
}


/* SWEET ALERT */
.sa {
  width: 140px;
  height: 140px;
  padding: 26px;
  background-color: #fff;
}


.sa-success {
  border-radius: 50%;
  border: 4px solid #A5DC86;
  box-sizing: content-box;
  height: 80px;
  padding: 0;
  position: relative;
  background-color: #fff;
  width: 80px;
}
.sa-success:after, .sa-success:before {
  background: #fff;
  content: "";
  height: 120px;
  position: absolute;
  transform: rotate(45deg);
  width: 60px;
}
.sa-success:before {
  border-radius: 40px 0 0 40px;
  width: 26px;
  height: 80px;
  top: -17px;
  left: 5px;
  transform-origin: 60px 60px;
  transform: rotate(-45deg);
}
.sa-success:after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  transform-origin: 0 60px;
  transform: rotate(-45deg);
  animation: rotatePlaceholder 4.25s ease-in;
}
.sa-success-placeholder {
  border-radius: 50%;
  border: 4px solid rgba(165, 220, 134, 0.25);
  box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2;
}
.sa-success-fix {
  background-color: #fff;
  height: 90px;
  left: 28px;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 5px;
  z-index: 1;
}
.sa-success-tip, .sa-success-long {
  background-color: #A5DC86;
  border-radius: 2px;
  height: 5px;
  position: absolute;
  z-index: 2;
}
.sa-success-tip {
  left: 14px;
  top: 46px;
  transform: rotate(45deg);
  width: 25px;
  animation: animateSuccessTip 0.75s;
}
.sa-success-long {
  right: 8px;
  top: 38px;
  transform: rotate(-45deg);
  width: 47px;
  animation: animateSuccessLong 0.75s;
}

@keyframes animateSuccessTip {
  0%, 54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes animateSuccessLong {
  0%, 65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes rotatePlaceholder {
  0%, 5% {
    transform: rotate(-45deg);
  }
  100%, 12% {
    transform: rotate(-405deg);
  }
}


.sa-warning {
  border-radius: 50%;
  border: 4px solid #F8BB86;
  box-sizing: content-box;
  height: 80px;
  padding: 0;
  position: relative;
  background-color: #fff;
  width: 80px;
  animation: scaleWarning 0.75s infinite alternate;
}
.sa-warning:after, .sa-warning:before {
  background: #fff;
  content: "";
  border-radius: 50%;
  height: 100%;
  position: absolute;
  width: 100%;
}
.sa-warning:before {
  display: inline-block;
  opacity: 0;
  animation: pulseWarning 2s linear infinite;
}
.sa-warning:after {
  display: block;
  z-index: 1;
}
.sa-warning-body {
  background-color: #F8BB86;
  border-radius: 2px;
  height: 47px;
  left: 50%;
  margin-left: -2px;
  position: absolute;
  top: 10px;
  width: 5px;
  z-index: 2;
  animation: pulseWarningIns 0.75s infinite alternate;
}
.sa-warning-dot {
  background-color: #F8BB86;
  border-radius: 50%;
  bottom: 10px;
  height: 7px;
  left: 50%;
  margin-left: -3px;
  position: absolute;
  width: 7px;
  z-index: 2;
  animation: pulseWarningIns 0.75s infinite alternate;
}

@keyframes scaleWarning {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulseWarning {
  0% {
    background-color: #fff;
    transform: scale(1);
    opacity: 0.5;
  }
  30% {
    background-color: #fff;
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    background-color: #F8BB86;
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes pulseWarningIns {
  0% {
    background-color: #F8D486;
  }
  100% {
    background-color: #F8BB86;
  }
}


.sa-error {
  border-radius: 50%;
  border: 4px solid #F27474;
  box-sizing: content-box;
  height: 80px;
  padding: 0;
  position: relative;
  background-color: #fff;
  width: 80px;
  animation: animateErrorIcon 0.5s;
}
.sa-error:after, .sa-error:before {
  background: #fff;
  content: "";
  height: 120px;
  position: absolute;
  transform: rotate(45deg);
  width: 60px;
}
.sa-error:before {
  border-radius: 40px 0 0 40px;
  width: 26px;
  height: 80px;
  top: -17px;
  left: 5px;
  transform-origin: 60px 60px;
  transform: rotate(-45deg);
}
.sa-error:after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  transform-origin: 0 60px;
  transform: rotate(-45deg);
  animation: rotatePlaceholder 4.25s ease-in;
}
.sa-error-x {
  display: block;
  position: relative;
  z-index: 2;
}
.sa-error-placeholder {
  border-radius: 50%;
  border: 4px solid rgba(200, 0, 0, 0.2);
  box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2;
}
.sa-error-fix {
  background-color: #fff;
  height: 90px;
  left: 28px;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 5px;
  z-index: 1;
}
.sa-error-left, .sa-error-right {
  border-radius: 2px;
  display: block;
  height: 5px;
  position: absolute;
  z-index: 2;
  background-color: #F27474;
  top: 37px;
  width: 47px;
}
.sa-error-left {
  left: 17px;
  transform: rotate(45deg);
  animation: animateXLeft 0.75s;
}
.sa-error-right {
  right: 16px;
  transform: rotate(-45deg);
  animation: animateXRight 0.75s;
}

@keyframes rotatePlaceholder {
  0%, 5% {
    transform: rotate(-45deg);
  }
  100%, 12% {
    transform: rotate(-405deg);
  }
}
@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes animateXLeft {
  0%, 65% {
    left: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    left: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    left: 17px;
    top: 37px;
    width: 47px;
  }
}
@keyframes animateXRight {
  0%, 65% {
    right: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    right: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    right: 16px;
    top: 37px;
    width: 47px;
  }
}

.sticky-timer-inactive {
  top: 0;
  transform: scale(1);
  transition: all .3s;
  backdrop-filter: blur(0px);
}

.sticky-timer-active {
  position: fixed !important;
  top: 9%;
  left: 49%;
  padding: 15px;
  z-index: 99999999 !important;
  transform: scale(.55);
  background-color: rgba(235, 235, 235, 0.652);
  border-radius: 10px;
  backdrop-filter: blur(2px);
  transition: all .3s;
}

@media screen and (max-width: 480px) {
  .sticky-timer-inactive {
    transform: scale(1);
    transition: all .3s;
  }

  .sticky-timer-active {
    top: 10%;
    transform: scale(.7);
    transition: all .3s;
  }
}

.bg-danger-light {
  background-color: #ffeded !important;
}

.button-resend-answer {
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-left-radius: 8.5px;
}

@media screen and (max-width: 576px) {
  .button-resend-answer {
    position: unset;
    border-bottom-left-radius: unset;
    border-radius: 5px;
  }
}

.custom-linkify {
  color: var(--link-color-linkify);
}

.custom-linkify:hover {
  color: var(--link-color-linkify) !important;
}

.custom-linkify:active {
  color: var(--link-color-linkify-active);
}

/* .custom-linkify-container a {
  color: var(--link-color-linkify);
}

.custom-linkify-container a:hover {
  color: var(--link-color-linkify);
} */

.formatted-text {
  white-space: pre-wrap;
}

.containerCkeditorReadonly2 a {
  pointer-events: all !important;
  cursor: pointer !important;
}

.containerCkeditorReadonly2 {
  --ck-inner-shadow: inset 0px 0px 0px 0px red !important;
  --ck-drop-shadow: unset !important;
  --ck-drop-shadow-active: unset !important;
  pointer-events: none !important;
}


.containerCkeditorReadonly2 .ck.ck-toolbar a {
  pointer-events: all !important;
  cursor: pointer !important;
}

.containerCkeditorReadonly2 .ck.ck-toolbar {
  border-color: unset !important;
  border: unset !important;
  pointer-events: none !important;
}

.containerCkeditorReadonly2 .ck.ck-editor__main>.ck-editor__editable a {
  pointer-events: all !important;
  cursor: pointer !important;
}

.containerCkeditorReadonly2 .ck.ck-editor__main>.ck-editor__editable {
  border-color: unset !important;
  border: unset !important;
  background-color: unset !important;
  pointer-events: none !important;
}

.containerCkeditorReadonly2 .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) a {
  pointer-events: all !important;
  cursor: pointer !important;
}

.containerCkeditorReadonly2 .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: unset !important;
  border: unset !important;
  pointer-events: none !important;
}

@media print {
  .element-no-print {
    display: none;
  }
}