.assignment-detail p {
  font-size: 14px;
}

.assignment-detail img {
  max-width: 100%;
}

.swal2-container {
  z-index: 999999 !important;
}
