#chat-room__text-input {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#chat-room__drop-zone {
  position : absolute !important;
  bottom: 65px;
  /* background-color: darkgray; */
}

#reply-container {
  border: 1px solid gray;
  box-shadow: grey 0px 0px 0px 1px;
  border-radius: 5px;
  background: white;
  padding: 10px;
}

#reply-container__close-button {
  font-size: 20px;
  top: 5px;
  right: 5px;
  float: right;
  color: dark;
  transition: .2s all ease;
}

#reply-container__close-button:hover {
  color: red;
  transform: scale(1.1) rotateZ(360deg);
}
